@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .about-paragraph p a {
      color: #3b82f6;
    }

    .about-list a {
      color: #3b82f6;
    }
  }